.main {
  background-blend-mode: multiply;
  text-align: center;
  background-color: #00000080;
  background-image: url("indexphoto2.78d25e2c.png");
  background-size: 100%;
  grid-column: 1 / 7;
  grid-template-columns: repeat(6, 1fr);
  max-width: 100%;
  animation-name: delayingActive;
  animation-duration: 2s;
  display: grid;
}

.main.contacts {
  background-blend-mode: multiply;
  background-color: #00000080;
  background-image: url("maincontacts.c61444b3.png");
}

.main-headtext {
  letter-spacing: -1px;
  grid-area: 1 / 2 / 1 / 6;
  margin-top: 25px;
  font-family: gilroy-extrabold;
  font-size: 40px;
}

.main-subtext--1 {
  grid-area: 2 / 2 / 2 / 6;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
}

.main-photo {
  grid-area: 3 / 1 / 3 / 7;
  width: 100%;
}

.main-container {
  grid-area: 3 / 2 / 3 / 6;
  width: 100%;
}

.submenu {
  grid-area: 2 / 2 / 2 / 6;
  grid-template-columns: auto;
  margin-left: 30px;
  margin-right: 30px;
  display: grid;
}

.media_block {
  grid-template-columns: repeat(4, 45px);
  max-width: 414px;
  display: grid;
}

.media_block--footer {
  grid-column: 2 / 4;
  grid-template-columns: repeat(4, 75px);
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.media_block.stock {
  grid-template-columns: repeat(4, 35px);
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.submenu_button {
  cursor: pointer;
  width: 30px;
  transition: all .5s;
}

.submenu_button.footer img {
  margin-left: 15px;
  margin-right: 15px;
}

.submenu_button:hover {
  opacity: .5;
  transition: all .5s;
}

.submenu_button img {
  width: 35px;
}

.submenu_button.stock img {
  width: 20px;
}

.section {
  display: none;
}

.section--active {
  grid-gap: 25px 25px;
  grid-column: 2 / 6;
  margin-bottom: 50px;
  margin-left: 25px;
  margin-right: 25px;
  animation-name: delayingActive;
  animation-duration: 2s;
  display: grid;
}

.content-kunteynir {
  grid-gap: 25px 25px;
  grid-template-rows: auto 90px;
  grid-template-columns: auto;
  display: grid;
}

.content-kunteynir-big {
  grid-gap: 15px 25px;
  grid-template-columns: auto;
  margin: auto;
  display: grid;
}

.content-kunteynir-small {
  grid-gap: 10px 10px;
  grid-template-rows: 32px 50px auto;
  display: grid;
}

.italic {
  font-style: italic;
}

.subsection {
  grid-gap: 20px 20px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.animate-charcter {
  text-align: left;
  text-indent: 35px;
  cursor: pointer;
  color: #000;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #2f2f2f 20%, #ff0 40% 60%, #2f2f2f 80%) 0 0 / 200%;
  -webkit-background-clip: text;
  background-clip: text;
  border: none;
  width: 290px;
  height: 40px;
  animation: 2s linear infinite shine;
}

@keyframes shine {
  to {
    background-position: 200%;
  }
}

.banner-find-h {
  -webkit-appearance: none;
  text-align: left;
  text-indent: 35px;
  cursor: pointer;
  background: #fff url("icon-search.3fdf0df2.svg") 10px / 15px no-repeat;
  border: .1px solid #a4a8a8;
  border-radius: 25px;
  height: 40px;
  margin-top: 20em;
  overflow: hidden;
}

@media screen and (device-width >= 700px) and (device-width <= 1024px) and (orientation: portrait) {
  .banner-find-h {
    margin-top: 10em;
  }
}

@keyframes drop {
  0% {
    opacity: 0;
    transform: translateY(200px)scaleY(.9);
  }

  5% {
    opacity: .5;
  }

  60% {
    opacity: .8;
    transform: translateY(0)scaleY(1);
  }

  100% {
    opacity: 1;
    transform: translateY(0)scaleY(1);
  }
}

.textdrop {
  animation: .8s linear forwards drop;
}

/*# sourceMappingURL=index.894646a8.css.map */
